var timelineManager = (function(){

    function init()
    {
        if (!$(".timeline-component").length) {
            return;
        }


        $(window).on("load", function () {
            $(".timeline-component").each(function(){
                stepsTitles = $(this).find(".steps-list-place .title-container");
                alignStepsTitleHeights(stepsTitles);
            })
        })

        $(window).on('shown.bs.tab', function (e) {
            var stepsTitles = $('.tab-pane.active.show .steps-list-place .title-container', e.target.closest('section'));
            console.log(stepsTitles);
            if (stepsTitles.length>0){
                alignStepsTitleHeights(stepsTitles, true);
            }
        })
    }

    function alignStepsTitleHeights(titles, resetHeight = false)
    {
        maxHeight = 0;
        titles.each(function(){
            if (resetHeight){
                $(this).height('auto');
            }

            if ($(this).height() > maxHeight) {
                maxHeight = $(this).height();
            }
        })
        titles.height(maxHeight);
    }

    return {
        init:init
    }
})(jQuery)