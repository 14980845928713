var popupCardsComponentCarouselManager = (function(){

    var popupCardsComponentCarouselObj = $(".popup-cards-component .owl-carousel");

    function init(){
        if(!$(".popup-cards-component").length) return;

        addEllipsis();
        $(document).ready(checkEnableCarousel);
        $(window).on('resize', checkEnableCarousel );
    }

    function addEllipsis(){
        cardsDescriptions = $(".popup-cards-component .card-description");
        nCharacters = $(window).width() > 992 ? 65 : 170;
        cardsDescriptions.each(function(){
            elements = $(this).find("p");
            $(this).find('ol').remove();
            $(this).find('ul').remove();
            if (elements.length>0){
                firstElementDescription= ($(elements[0]).text());
                if(firstElementDescription.length > nCharacters){
                    ellipsedDescription = firstElementDescription.substr(0,nCharacters) + '\u2026';
                    $(elements[0]).text(ellipsedDescription);
                }
                if (elements.length>1){
                    for (i=1; i<elements.length; i++){
                        $(elements[i]).remove();
                    }
                }
            }
        })
    }

    function checkEnableCarousel(){
        if($(window).width() > 992){
            $(".popup-cards-component").each(function(){
                $(this).find(".cards-section").addClass("row");
            })
        } else {
            $(".popup-cards-component").each(function(){
                $(this).find(".cards-section").removeClass("row");
            })
        }
    }

    return {
        init: init
    }

})(jQuery);